import React from 'react';
import {useTranslation} from "react-i18next";

import CompatibilityCardIcon from "@/components/CompatibilityCardIcon";

const CompatibilityCard = ({status, label, content}) => {
  const {t} = useTranslation();
  
  return (
      <div className="flex items-center gap-3 text-gray-400">
        <CompatibilityCardIcon status={status} />
        
        <div className={`text-${status === 'error' ? 'red-400' : 'gray-400'}`}>
          <span className="mr-2 font-semibold text-gray-500">
            {label}:
          </span>
          {content === 'active' || content === 'not_active' ? t(`steps.1.${content}`) : content}
        </div>
      </div>
  );
};

export default CompatibilityCard;