import React, {useContext, useEffect, useState} from 'react';
import {When} from "react-if";
import {useTranslation} from "react-i18next";
import ClickAwayListener from 'react-click-away-listener';
import {getLanguages} from "@/lib/axios";
import InstallerContext from "@/context/InstallerContext";

const LanguageSelector = () => {
  const {i18n} = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [languages, setLanguages] = useState([]);
  const {updateGeneralData} = useContext(InstallerContext);
  
  const handleChange = (lang) => {
    setDropdownOpen(false);
    updateGeneralData("language", lang);
    i18n.changeLanguage(lang);
  }
  
  useEffect(() => {
    getLanguages().then(({data}) => {
      setLanguages(data);
    });
  }, []);
  
  return (
      <ClickAwayListener onClickAway={() => setDropdownOpen(false)}>
        <div className="relative w-full">
          <button onClick={() => setDropdownOpen(!dropdownOpen)} className="input text-left w-full">
            {languages[i18n.language]}
          </button>
          
          <When condition={dropdownOpen}>
            <div className="absolute w-full rounded-xl bg-gray-100 border border-gray-200 py-2 h-52 overflow-auto top-14">
              {Object.keys(languages).map((key) =>
                  <div key={key} onClick={() => handleChange(key)} className="py-2 px-4 text-gray-500 text-sm transition hover:bg-gray-200/75 cursor-pointer">
                    {languages[key]}
                  </div>
              )}
            </div>
          </When>
        </div>
      </ClickAwayListener>
  );
};

export default LanguageSelector;