import React from 'react';

const ButtonWrapper = ({children, align = "between"}) => {
  return (
      <div className={`mt-auto w-full flex justify-${align} items-center`}>
        {children}
      </div>
  );
};

export default ButtonWrapper;