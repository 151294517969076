import React from 'react';
import {useTranslation} from "react-i18next";

const ErrorMessage = ({errors = []}) => {
  const {t} = useTranslation()
  
  if (errors.length === 0) return null;
  return (
      <div className="mt-6 rounded-xl py-4 px-6 text-red-400 bg-red-50">
        {errors.map((error, index) =>
            <span key={index}>{t(error)}</span>
        )}
      </div>
  );
};

export default ErrorMessage;