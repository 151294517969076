import React from 'react';

const Step = ({children}) => {
  return (
      <div className="top-0 w-full h-full flex-col flex py-7 px-6 md:py-14 md:px-12">
        {children}
      </div>
  );
};

export default Step;