import React from 'react';

import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";

const PrevButton = ({previousStep}) => {
  const {t} = useTranslation();
  
  return (
      <div onClick={previousStep} className="cursor-pointer rounded-xl py-3 px-6 flex gap-3 bg-gray-400/[10%] text-gray-600/75 items-center font-medium group transition hover:bg-gray-400/25 hover:text-gray-500">
        <div className="w-10 h-10 flex items-center justify-center rounded-xl bg-gray-300/75 group-hover:bg-gray-400 transition">
          <FontAwesomeIcon icon={faAngleLeft} className="text-xl group-hover:text-white transition" />
        </div>
        {t('prev')}
      </div>
  );
};

export default PrevButton;