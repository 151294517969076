import React from 'react';

const Label = ({children, htmlFor}) => {
  return (
      <label htmlFor={htmlFor} className="label">
        {children}
      </label>
  );
};

export default Label;
