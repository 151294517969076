import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";

const CompatibilityCardIcon = ({status}) => {
  switch (status) {
    case 'success':
      return (
          <div className="bg-emerald-100 rounded-xl w-8 h-8 flex items-center justify-center">
            <FontAwesomeIcon icon={faCheck} className="text-emerald-400" />
          </div>
      );
    case 'warning':
      return (
          <div className="bg-yellow-200/75 rounded-xl w-8 h-8 flex items-center justify-center">
            <FontAwesomeIcon icon={faTriangleExclamation} className="text-yellow-500" />
          </div>
      )
    case 'error':
      return (
          <div className="bg-red-100 rounded-xl w-8 h-8 flex items-center justify-center">
            <FontAwesomeIcon icon={faTimes} className="text-red-400" />
          </div>
      );
    default:
        return null;
  }
};

export default CompatibilityCardIcon;