import React from 'react';

import {useTranslation} from "react-i18next";
import {If, Then, Else} from "react-if";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";

const NextButton = ({nextStep, disabled, loading}) => {
  const {t} = useTranslation();
  if (loading) disabled = true;
  return (
      <div onClick={nextStep} className={`cursor-pointer rounded-xl py-3 px-6 flex gap-3 bg-vox/[10%] text-vox/75 items-center group font-medium transition hover:bg-vox/25 hover:text-vox ${disabled && 'disabled-next-button'}`}>
        {loading ? t('processing') : t('next')}
        <div className="w-10 h-10 flex items-center justify-center rounded-xl bg-vox/25 group-hover:bg-vox transition">
          <If condition={loading}>
            <Then>
              <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </Then>
            <Else>
              <FontAwesomeIcon icon={faAngleRight} className="text-xl group-hover:text-white transition" />
            </Else>
          </If>
        </div>
      </div>
  );
};

export default NextButton;