import React from 'react';

const BackgroundCard = ({children, color = "gray-50"}) => {
  return (
      <div className={`bg-${color} rounded-xl mt-6`}>
        {children}
      </div>
  );
};

export default BackgroundCard;