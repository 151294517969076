import React, {useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import InstallerContext from "@/context/InstallerContext";

import LanguageSelector from "@/components/LanguageSelector";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faQuestion} from "@fortawesome/free-solid-svg-icons";

import logo from '@/images/leaderos-icon.png';

const Welcome = () => {
  const {setWelcome, updateGeneralData} = useContext(InstallerContext);
  
  const nextStep = () => setWelcome(false);
  const {t, i18n} = useTranslation();
  
  useEffect(() => {
    updateGeneralData("language", i18n.language);
  }, []);
  
  
  return (
      <div className="w-full my-auto flex flex-col items-center justify-center">
        <div className="-mt-16">
            <img src={logo} alt="" className="w-32" />
        </div>
        <h1 className="text-gray-800 mt-6 text-3xl font-bold text-center">
          {t('welcome.title')}
        </h1>
        <p className="text-gray-400/75 max-w-xl text-lg text-center mt-3">
          {t('welcome.description')}
        </p>
        <div className="flex mt-10 w-full md:w-1/3  px-4 md:px-0">
          <LanguageSelector />
        </div>
        <div className="flex mt-6 w-full md:w-1/3 px-4 md:px-0">
          <div className="w-full flex justify-between gap-3">
            <a href="https://help.leaderos.net" target="_blank" rel="noreferrer" className="min-w-40 rounded-xl bg-emerald-50 py-3 px-6 flex gap-3 items-center text-emerald-400 font-medium group transition hover:bg-emerald-100 hover:text-emerald-500">
              <div className="w-10 h-10 flex items-center justify-center rounded-xl bg-emerald-100 group-hover:bg-emerald-400 transition">
                <FontAwesomeIcon icon={faQuestion} className="text-xl group-hover:text-white transition" />
              </div>
              {t('docs')}
            </a>
            <div onClick={nextStep} className="min-w-40 rounded-xl bg-vox/[10%] py-3 px-6 flex justify-end x gap-3 items-center text-vox/75 font-medium group transition hover:bg-vox/25 hover:text-vox cursor-pointer">
              {t('next')}
              <div className="w-10 h-10 flex items-center justify-center rounded-xl bg-vox/25 group-hover:bg-vox transition">
                <FontAwesomeIcon icon={faAngleRight} className="text-xl group-hover:text-white transition" />
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Welcome;