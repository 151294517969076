import React, {useState, createContext} from 'react';

export const initialState = {
  welcome: true,
  compatibility: [],
  database: {
    host: "localhost",
    port: "3306",
    database: null,
    username: null,
    password: null,
  },
  general: {
    serverName: null,
    serverIp: null,
    serverVersion: null,
    websiteTitle: null,
    passwordEncryption: "1",
    maintenance: "0",
    language: null,
    currency: null,
    timezone: null,
  },
  admin: {
    username: null,
    email: null,
    password: null,
    confirmPassword: null,
  }
}

const InstallerContext = createContext();

export function InstallerProvider({children}) {
  const [data, setData] = useState(initialState);
  
  const updateData = (newData) => {
    setData({
      ...data,
      newData,
    });
  }
  
  const updatePathKeyValue = (path, key, value) => {
    setData({
      ...data,
      [path]: {
        ...data[path],
        [key]: value,
      },
    });
  }
  
  const updateDatabaseData = (key, value) => {
    updatePathKeyValue('database', key, value);
  }
  
  const updateGeneralData = (key, value) => {
    updatePathKeyValue('general', key, value);
  }
  
  const updateAdminData = (key, value) => {
    updatePathKeyValue('admin', key, value);
  }
  
  const setWelcome = (value) => {
    setData({
      ...data,
      welcome: value,
    });
  }
  
  const setCompatibility = (value) => {
    setData({
      ...data,
      compatibility: value,
    });
  }
  
  const value = {
    data,
    updateData,
    setWelcome,
    setCompatibility,
    updateDatabaseData,
    updateGeneralData,
    updateAdminData,
  };
  return <InstallerContext.Provider value={value}>{children}</InstallerContext.Provider>;
}

export default InstallerContext;