import React from 'react';
import {useTranslation} from "react-i18next";

const CompatibilityLoading = () => {
  const {t} = useTranslation();
  
  return (
      <div className="bg-blue-50 rounded-xl mt-6 py-6 px-10 text-blue-500">
        <div className="mx-auto w-20 h-20 rounded-2xl flex items-center justify-center">
          <svg className="animate-spin h-12 w-12" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
        <div className="max-w-lg mt-4 text-center mx-auto text-lg text-blue-400">
          {t('steps.1.loading')}
        </div>
      </div>
  );
};

export default CompatibilityLoading;