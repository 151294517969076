import React from 'react';

const StepTitle = ({children}) => {
  return (
      <h1 className="text-gray-800 font-bold text-2xl">
        {children}
      </h1>
  );
};

export default StepTitle;